<template>
  <footer class="section-footer">
    <el-container>
      <el-main>
        <el-row :gutter="20" class="principal-container-footer">
          <el-col :xs="24" :sm="11" :md="9">
            <LadNavigationLogo />

            <p class="lad-description">{{ $t(`${base}.description`) }}</p>

            <el-row class="sub-row-list">
              <el-col :span="12">
                <ul class="list-unstyled">
                  <li>
                    <nuxt-link to="/about-us">{{
                      $t(`${base}.about_us`)
                    }}</nuxt-link>
                  </li>
                  <li>
                    <nuxt-link to="/why-choose-us">{{
                      $t(`${base}.why_choose_us`)
                    }}</nuxt-link>
                  </li>
                  <li>
                    <a :href="blog" target="_blank" rel="noreferrer">{{
                      $t(`${base}.blog`)
                    }}</a>
                  </li>
                </ul>
              </el-col>
              <el-col :span="12">
                <ul class="list-unstyled">
                  <!-- <li v-if="isBuenFin"> -->
                  <li>
                    <nuxt-link to="/buen-fin">{{
                      $t(`${base}.buen_fin`)
                    }}</nuxt-link>
                  </li>
                  <client-only>
                    <li v-if="!isIOS && hasDeferredPrompt && !isPwaEnabled">
                      <a @click="handleInstallManually">{{
                        $t(`pwa.install_manually_pwa`)
                      }}</a>
                    </li>
                  </client-only>
                  <!-- <li v-else>
                    <nuxt-link to="/announcement">{{
                      $t(`${base}.announcement`)
                    }}</nuxt-link>
                  </li> -->
                </ul>
              </el-col>
            </el-row>
          </el-col>

          <template v-if="!(isMobile && isLanding)">
            <el-col :xs="24" :sm="6" :md="5">
              <p class="title">{{ $t(`${base}.top_destinations.title`) }}</p>

              <ul class="list-unstyled list-top-destinations">
                <li v-for="(destination, key) in topDestinations" :key="key">
                  <nuxt-link
                    :to="{
                      name: 'search-type',
                      params: {
                        type: $t(`router.search_type.type.${destination.type}`),
                        value: $t(
                          // eslint-disable-next-line vue/comma-dangle
                          `${base}.top_destinations.${destination.slug}.slug`
                        ),
                        tab: $t('router.search_type.tab.tours'),
                      },
                    }"
                    @click.native="handleCustomLoading"
                    >{{
                      $t(`${base}.top_destinations.trips_to`, {
                        name: $t(
                          // eslint-disable-next-line vue/comma-dangle
                          `${base}.top_destinations.${destination.slug}.name`
                        ),
                      })
                    }}</nuxt-link
                  >
                </li>
              </ul>
            </el-col>
            <el-col :xs="24" :sm="7" :md="5">
              <p class="title">{{ $t(`${base}.for_tour_operators`) }}</p>
              <ul class="list-unstyled">
                <li>
                  <a
                    href="https://localadventures.zoholandingpage.com/supply/welcome-providers/"
                    target="_blank"
                    rel="noreferrer"
                    >{{ $t(`${base}.work_with_us`) }}</a
                  >
                </li>
                <li>
                  <a
                    href="https://dashboard.localadventures.io"
                    target="_blank"
                    rel="noreferrer"
                    >{{ $t(`${base}.operators_access`) }}</a
                  >
                </li>
              </ul>

              <p class="title">{{ $t(`${base}.for_travel_agencies`) }}</p>
              <ul class="list-unstyled">
                <li>
                  <nuxt-link to="/approved-partner">{{
                    $t(`${base}.work_with_us`)
                  }}</nuxt-link>
                </li>
                <li>
                  <a
                    href="https://dashboard.localadventures.io"
                    target="_blank"
                    rel="noreferrer"
                    >{{ $t(`${base}.b2b_access`) }}</a
                  >
                </li>
              </ul>
            </el-col>
            <el-col :xs="24" :md="5" class="container-back-to-top">
              <LadBackToTop />
            </el-col>
          </template>
        </el-row>

        <el-row
          :gutter="20"
          class="container-associated-with container-associated-with-logos"
        >
          <el-col :xs="24" :sm="9">
            <p class="title">{{ $t(`${base}.associated_with`) }}</p>

            <div class="wrapper--logos">
              <template v-for="logo in logos">
                <a
                  v-if="logo.type === 'link'"
                  :key="`a-${logo.key}`"
                  :href="logo.cta"
                  target="_blank"
                  :aria-label="logo.ariaLabel"
                  class="container-img"
                >
                  <img
                    :src="require(`@/assets/images/footer/${logo.name}.png`)"
                    :alt="logo.alt || logo.title"
                    :title="logo.title"
                    loading="lazy"
                    class="sprite-icons"
                  />
                </a>
                <span
                  v-else
                  :key="`b-${logo.key}`"
                  class="container-img logo-img-wrapper"
                  @click="handleOpenImg(logo)"
                >
                  <img
                    :src="require(`@/assets/images/footer/${logo.name}.png`)"
                    :alt="logo.alt || logo.title"
                    :title="logo.title"
                    loading="lazy"
                    class="sprite-icons"
                  />
                </span>
              </template>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" class="container-associated-with">
          <el-col :xs="24" :sm="9">
            <div class="wrapper--logos">
              <template v-for="logo in logos">
                <span v-if="logo.alt" :key="logo.key" class="logo-alt">{{
                  logo.alt
                }}</span>
              </template>
            </div>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row :gutter="20" class="footer-wrapper-bottom">
          <el-col :xs="24" :sm="14">
            <p class="footer-copyright"
              >{{ $t(`${base}.copyright`, { year }) }} <br />
              <nuxt-link to="/privacy-policy">{{
                $t(`${base}.privacy_policy`)
              }}</nuxt-link>
              <span>&#38;</span>
              <nuxt-link to="/terms-and-conditions">{{
                $t(`${base}.terms_and_conditions`)
              }}</nuxt-link></p
            >
          </el-col>
          <el-col :xs="24" :sm="10" class="container-social-networks">
            <div class="container-currency-language">
              <el-dropdown trigger="click" @command="handleSelectCurrency">
                <span class="el-dropdown-link">
                  <i class="fas fa-dollar-sign"></i> {{ currency }}
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="currency in currencies"
                    :key="currency.code"
                    :disabled="currency.code === currency"
                    :index="currency.code"
                    :command="currency.code"
                  >
                    <span>{{ currency.code }}</span></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>

              <el-dropdown trigger="click" @command="handleSelectLanguage">
                <span class="el-dropdown-link">
                  <i class="fa fa-globe"></i> {{ currentLanguage?.name }}
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="language in locales"
                    :key="language.code"
                    :disabled="language.code === locale"
                    :index="language.code"
                    :command="language.code"
                  >
                    <template v-if="isTravelWebsite">
                      <span class="lad-navigation--flag">{{
                        language.flag_code
                      }}</span>
                      <span>{{ language.name }}</span>
                    </template>
                    <a v-else :href="links[language.code]">
                      <span class="lad-navigation--flag">{{
                        language.flag_code
                      }}</span>
                      <span>{{ language.name }}</span>
                    </a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <div class="container-facebook-instagram">
              <a
                :href="facebook"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
                alt="Facebook"
                class="link-facebook"
                ><i class="fab fa-facebook-square"></i
              ></a>
              <a
                :href="instagram"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
                alt="Instagram"
                class="link-instagram"
                ><i class="fab fa-instagram"></i
              ></a>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>

    <LadDialog
      v-if="secturDialog.open"
      :visible="secturDialog.open"
      :handle-before-close="handleCloseImg"
      class="section--logo-img-footer"
    >
      <template slot="dialog-body">
        <LadImage v-if="false" :src="secturDialog.img" class="image--cover" />
        <img :src="secturDialog.img" width="100%" />
      </template>

      <template slot="dialog-footer">
        <el-button @click="handleCloseImg">{{ $t('general.close') }}</el-button>
      </template>
    </LadDialog>
  </footer>
</template>

<script>
import Cookies from 'js-cookie'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'LadFooter',
  components: {
    LadDialog: () =>
      import(
        /* webpackChunkName: "lad-dialog" */ '@/components/frontends/Modal/index'
      ),
    LadImage: () =>
      import(
        /* webpackChunkName: "lad-image" */ '@/components/frontends/Image/index'
      ),
    LadNavigationLogo: () =>
      import(
        /* webpackChunkName: "lad-navi-logo" */ '@/components/layout/Navigation/components/Logo'
      ),
    LadBackToTop: () =>
      import(
        /* webpackChunkName: "lad-back-to-top" */ '@/components/layout/back-to-top'
      ),
  },
  data() {
    return {
      base: 'footer',
      section1: null,
      section2: null,
      section3: null,
      section4: null,
      secturDialog: {
        open: false,
        img: null,
      },
      topDestinations: [
        {
          slug: 'europe',
          type: 'continent',
        },
        {
          slug: 'peru',
          type: 'country',
        },
        {
          slug: 'egypt',
          type: 'country',
        },
        {
          slug: 'canada',
          type: 'country',
        },
      ],
    }
  },
  computed: {
    /** https://forum.vuejs.org/t/dont-understand-how-to-use-mapstate-from-the-docs/14454/6 */
    ...mapGetters('device', ['isMobile', 'isIOS']),
    ...mapState('settings', ['currencies', 'currency', 'locale', 'locales']),
    ...mapState('app', {
      isPwaEnabled: (state) => state.pwa.isEnabled,
      hasDeferredPrompt: (state) => !!state.pwa.deferredPrompt,
    }),
    /** currentLanguage, links and isTravelWebsite only used in dropdown language */
    currentLanguage() {
      const current = this.locales.find((language) => {
        return language.code === this.locale
      })
      return current
    },
    links() {
      const items = {}

      this.locales.forEach((item) => {
        switch (item.code.toUpperCase()) {
          case 'ES_MX':
            items[item.code] = process.env.APP_URL_ES_MX + this.$route.fullPath
            break
          case 'ES_CO':
            items[item.code] = process.env.APP_URL_ES_CO + this.$route.fullPath
            break
          case 'EN_US':
            items[item.code] = process.env.APP_URL_EN_US + this.$route.fullPath
            break
          default:
            break
        }
      })

      return items
    },
    isTravelWebsite() {
      return process.env.APP_URL === process.env.APP_URL_EN_US
    },
    year() {
      return new Date().getFullYear()
    },
    instagram() {
      /**
       * https://www.instagram.com/localadventures.mx/
       * https://www.instagram.com/localadventures.travel/
       */
      const url = 'https://www.instagram.com/localadventures.{domain}/'

      return url.replace(
        '{domain}',
        this.currency.toLowerCase() === 'mxn' ? 'mx' : 'travel'
      )
    },
    facebook() {
      return process.env.FACEBOOK_URL
    },
    blog() {
      return `${process.env.APP_URL}/blog`
    },
    svgIconName() {
      return `payments/localadventures-payments-${this.currency.toLowerCase()}-white`
    },
    logos() {
      const list = []

      if (this.currency.toLowerCase() === 'mxn') {
        list.push({
          name: 'sectur-logo',
          key: 'sectur-logo',
          type: 'img',
          cta:
            'https://cdn.localadventures.io/web-client/statics/SECTUR-LocalAdventures.jpeg',
          alt: 'SECTUR: No. 27140390009',
          title: this.$t(`${this.base}.title.sectur`),
          ariaLabel: null,
        })
        list.push({
          name: 'amvo-logo',
          key: 'amvo-logo',
          type: 'link',
          cta: 'https://www.amvo.org.mx/miembro/Local%20Adventures',
          alt: null,
          title: this.$t(`${this.base}.title.amvo`),
          ariaLabel: this.$t(`${this.base}.aria_label.amvo`),
        })
      }

      list.push({
        name: 'atta-logo',
        key: 'atta-logo',
        type: 'link',
        cta: 'https://membership.adventuretravel.biz/tour-operator/active',
        alt: null,
        title: this.$t(`${this.base}.title.adventure_travel`),
        ariaLabel: this.$t(`${this.base}.aria_label.adventure_travel`),
      })

      return list
    },
    isBuenFin() {
      return ['buen-fin'].includes(this.$route.name)
    },
    isLanding() {
      return ['landing-permalink-test'].includes(this.$route.name)
    },
  },
  methods: {
    handleInstallManually() {
      this.$store.dispatch('app/setInstallManually', true)
    },
    handleOpenImg(logo) {
      this.secturDialog = {
        open: true,
        img: logo.cta,
      }
    },
    handleCloseImg() {
      this.secturDialog = {
        open: false,
        img: null,
      }
    },
    async handleSelectCurrency(code) {
      if (this.isAuth) {
        this.$axios
          .put(`/api/v1.5/users/update/currency`, { code })
          .then((response) => {
            if (response.status === 'success') {
              this.$router.go()
            }
          })
      } else {
        await Cookies.set('currency', code)
        this.$router.go()
      }
    },
    async handleSelectLanguage(code) {
      if (this.isTravelWebsite) {
        await Cookies.set('language', code)

        if (this.isAuth) {
          await this.$axios
            .put(`/api/v1.5/users/update/language`, { code })
            .then((response) => {
              if (response.status === 'success') {
                this.$router.go()
              }
            })
        } else {
          this.$router.go()
        }
      }
    },
    handleCustomLoading() {
      this.$nuxt.$emit('custom-loading', { enabled: true, source: 'search' })
    },
  },
}
</script>

<style lang="scss">
.section-footer {
  background-color: white;
  color: black;
  padding-bottom: 130px;

  @media (min-width: 992px) {
    padding-bottom: 0;
  }

  .title {
    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.29px; /* 152.071% */
    text-transform: uppercase;
  }

  .el-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .list-unstyled {
    li {
      padding-left: 0;
    }
  }

  .footer-wrapper-before {
    margin-bottom: 25px;

    .sprite-icons {
      max-height: 45px;
    }

    .sectur-logo {
      width: 200px;
    }

    .atta-logo {
      width: 220px;
    }

    .table {
      & > div:first-child {
        width: 150px;
      }

      @media screen and (max-width: 700px) {
        &,
        & > * {
          display: inherit;
        }

        & > div:last-child {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;

          .sprite-icons {
            width: 100%;
          }
        }
      }
    }
  }

  .footer-social-networks {
    font-size: 3em;

    p {
      line-height: 1;
    }
  }

  .footer-wrapper-middle {
    margin: 2.5em 0;
  }

  .footer-payments-logo {
    text-align: center;
  }

  .footer-copyright {
    text-align: center;

    a[href] {
      text-decoration: underline;
    }
  }

  .el-collapse {
    border: 0;

    .el-collapse-item {
      &__header {
        border: 0;
        font-size: 1.17em;
        text-transform: uppercase;
      }

      &__arrow {
        font-size: 12px;
      }

      &__wrap {
        border: 0;
      }

      &__content {
        padding-bottom: 0;
      }
    }
  }

  .wrapper--logos {
    & > * {
      display: inline-block;
    }
  }

  .logo-img-wrapper {
    cursor: pointer;

    svg,
    span {
      float: left;
    }

    span {
      clear: both;
      margin-top: 10px;
      color: white;
      font-size: 0.9em;
    }
  }

  .section--logo-img-footer {
    .el-dialog {
      width: 750px;
      max-width: 90%;

      &__body {
        min-height: 200px;
        overflow-x: auto;

        img {
          max-width: inherit;
        }
      }

      @media screen and (max-width: 450px) {
        margin-top: 15vh;
        height: auto;
      }

      @media screen and (max-height: 500px) {
        height: 98vh;
        margin-top: 1vh;
      }
    }
  }

  .el-collapse-item__header,
  .el-collapse-item__wrap {
    background-color: transparent;
    color: white;
  }

  @media screen and (max-width: 991px) {
    .footer-social-networks,
    .footer-payments-logo {
      text-align: center;
    }

    .footer-payments-logo {
      margin: 20px 0 0;
    }
  }

  @media screen and (max-width: 700px) {
    .el-row:not(.footer-wrapper-before) {
      margin: 0 10px !important;
    }

    .footer-wrapper-before {
      margin: 0 10px 25px !important;
    }

    .footer-wrapper-middle {
      margin: 1.5em 0 !important;
    }
  }

  @media screen and (max-width: 525px) {
    img {
      width: auto;
      max-width: 100%;
    }
  }

  .principal-container-footer {
    color: black;

    .lad-description {
      color: #666;
      line-height: 22.2px;
    }

    .sub-row-list {
      margin: 0 !important;

      .list-unstyled {
        margin: 0;

        a {
          color: #000;
        }
      }
    }

    .lad-navigation--logo {
      a {
        float: unset;

        .sprite-icons {
          max-width: 110px;
          max-height: 36px;
        }
      }
    }

    .title {
      margin: 30px 0 20px;
    }

    .list-unstyled {
      color: black;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 200% */

      a {
        color: #000;
      }
    }

    .list-top-destinations {
      margin-bottom: 0;
    }

    .container-back-to-top {
      text-align: right;
    }
  }

  .container-associated-with {
    .wrapper--logos {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      max-height: 50px;

      & > * {
        display: inline-block;
      }

      span,
      a {
        &.container-img {
          max-width: 33%;
        }

        .sprite-icons {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .logo-alt {
        color: #909399;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.49px; /* 131.727% */
        margin-top: 0;
      }
    }
  }

  .footer-wrapper-bottom {
    .footer-copyright {
      text-align: center;
      color: #757575;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 25.2px; /* 180% */

      @media (min-width: 768px) {
        text-align: left;
      }

      br {
        @media (min-width: 768px) {
          display: none;
        }
      }

      & a {
        color: black;
      }
    }

    .container-social-networks {
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;

      @media (min-width: 768px) {
        justify-content: flex-end;
        margin-top: 0;
      }

      .el-dropdown {
        .el-dropdown-link {
          cursor: pointer;
          color: black;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 200% */

          .fa,
          .fas {
            color: #909399;
            font-weight: 900;
            line-height: 14px; /* 100% */
          }
        }

        &:last-of-type {
          margin-left: 20px;
        }
      }

      .fab {
        color: black;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
      }

      .link-facebook,
      .link-instagram {
        display: inline-block;
        margin-left: 20px;
      }
    }
  }

  .principal-container-footer,
  .container-associated-with,
  .footer-wrapper-bottom {
    &.el-row {
      margin: 0 20px !important;

      @media (min-width: 768px) {
        margin: 0 !important;
      }
    }

    &-logos {
      &.el-row {
        margin: 20px 20px 0 !important;

        @media (min-width: 768px) {
          margin: 0 !important;
        }
      }
    }
  }
}
</style>
